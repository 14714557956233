import React, { useState,useEffect } from 'react';
import auth0 from 'auth0-js';
import { ProcessErrors } from '../../Utilities/ErrorBox';
import { Loading } from '../../Utilities/Loading';
import { APIGet, APIPost, APIRedirect, getEnvironment} from '../../API/APIRequest';
import { GetConfiguration } from '../../Configuration/ConfigurationManager';
import { GetADToken, SaveADToken, RefreshADToken, GetAuth0TokenLocal, RemoveADToken, RemoveAuth0Token, SaveAuth0Token } from '../../Utilities/TokenManager'
import { GetCookieName, GetCookieValue, ValidateCookieValue, DeleteCookie, SaveCookie} from '../../Utilities/ADFECookie'

function useProvideADNativeAuth() {
   
    const [isLoading, setIsLoading] = useState(true);
    const [userData, setUserData] = useState(null);
    const [errors, setErrors] = useState([]);
    const [webAuth, setWebAuth] = useState(null)
    const [environment, setEnvironment] = useState(getEnvironment());
    const [currentAction, setCurrentAction] = useState([]);
    const [returnUrl, setReturnUrl] = useState(null);
    const authProviderType = "adnative"; 
  


    const params = new Proxy(new URLSearchParams(window.location.search),
        {
            get: (searchParams, props) => searchParams.get(props),
        });

    const getWebAuth = () => {
        if (!webAuth) {
            GetConfiguration("auth0authenticationapiconfiguration", environment).then((configuration) => {
                var wa = new auth0.WebAuth(
                    {
                        domain: configuration.customDomain,
                        clientID: configuration.clientId,
                        responseMode: 'query',
                        responseType: 'code',
                        scope: 'openid profile email offline_access'
                    });
                setWebAuth(wa);
            }).catch((webauthErrors) => {
                errors.push(webauthErrors);
            });
        }
    }

    const PassThruUrl = () => {
        let params = (new URL(document.location)).searchParams;
        return params.get("PassThruUrl")
        
    }

    const ForwardPage = () => {
        
    }
    
    const ReturnUrl = () => {
        
        let params = (new URL(document.location)).searchParams;
        let retUrl = params.get("ReturnUrl") || params.get("returl") ;
        if (retUrl){
           
            setReturnUrl(retUrl);
            return retUrl;

            } 

        if (!returnUrl ) {
              //return new Promise((resolve, reject) => {
        let params = (new URL(document.location)).searchParams;
        let retUrl = params.get("ReturnUrl") || params.get("returl") ;
           
            if (!retUrl) {
                retUrl = document.referrer;
            }
            if (!retUrl) {
                retUrl = sessionStorage.getItem("ReturnUrl");
            }
            if (retUrl) {
                setReturnUrl(retUrl);
                //resolve(returnUrl);
            }
            if (!retUrl) {
                APIGet({
                    "controller": "brand",
                    "action": "getcurrentbrand",
                    "environment": environment
                }).then((response) => {

                    if (response?.data?.brand?.returnurl) {
                        sessionStorage.setItem("ReturnUrl", response.data.brand.returnurl);
                        setReturnUrl(response.data.brand.returnurl);
                    }
                    else {
                        let fallbackUrl = "https://" + response.data.brand.brandhostname + "/thankyou";
                        sessionStorage.setItem("ReturnUrl",fallbackUrl);
                        setReturnUrl(fallbackUrl);
                    }
                    //resolve(returnUrl);
                }).
                catch((error) => {
                    //reject(error);
                })
            }
        }
        return returnUrl;
        //})
    }

    const ClearUserData = () => {
        RemoveADToken();
        setUserData(null);
    }

    const AuthenticateWithCookie = () => {
        return new Promise((resolve, reject) => {
            GetCookieName().then((cookieName) => {
                var cookieValue = GetCookieValue(cookieName);
                if (cookieValue) {
                    ValidateCookieValue(cookieValue).then((user) => {
                        if (user) {
                            var userToken = {
                                "name": user.userfullname,
                                "token": user.userToken,
                                "partyid": user.partyid,
                                "username": user.username
                            };
                            SaveADToken(userToken);
                            resolve(userToken);
                        }
                        resolve(null);
                    });
                }
                resolve(null);
            });

        });

    }

    const Login = (username, password) => {
        return new Promise((resolve, reject) => {    
            var data = {
                        Username: username,
                        Password: password
                    };               
            APIPost({
                "controller": "account",
                "action": "login",
                "environment": environment,
                data: data       
            }).then((axresponse) => {
                var axresult = axresponse.data;
                if (!axresult.success) {
                    reject(axresult.errors);
                }
                var user = axresult.user;
                if (user) {
                    var userToken = {
                        "name": user.userfullname,
                        "token": user.userToken,
                        "partyid": user.partyid,
                        "username": user.username
                    };
                    SaveAuth0Token(axresult.ad_ref_token);
                    SaveADToken(userToken);
                    setUserData(userToken);
                    resolve(axresult);
                }                       
            }).catch((axerror) => {
                reject(axerror);
            });            
        });
    }

    const ClearAuth0Tokens = (refToken) => {
        return new Promise((resolve, reject) => {
            var formData = new FormData();
            formData.append("refToken", refToken);
            APIPost({
                "controller": "auth0",
                "action": "cleartokens",
                "environment": environment,
                data: formData
            }).then((axresult) => {
                resolve(axresult.data.success);
            }).catch((axerror) => {
                reject(axerror);
            });
        });
    }

    const AutoLogin = (refParam) => {
        return new Promise((resolve, reject) => {
            if (currentAction.length == 0) {
                currentAction.push("authenticating");
               
                currentAction.pop();
                resolve(null);                
            }
        });
    }
    
    const getUserToken = () => {
        return new Promise((resolve, reject) => {
            var refToken = params.refToken;
            var userToken = GetADToken();   
            if (isLoading) {
                GetConfiguration("userconfiguration", environment).then((configuration) => {
                    if (!userToken?.token) {                       
                        AutoLogin(refToken).then((userToken) => {
                            resolve(userToken);
                        }).catch((aerrors) => {
                            reject(aerrors);
                        });                    
                    }
                    else {
                        resolve(userToken);
                    }
                }).catch((cerrors) => {
                    reject(cerrors);
                })
            }
            else {
                resolve(userToken);
            }
        });

    }

    const GetLoginData = (environment, refToken) => {
        return new Promise((resolve, reject) => {
            APIGet({
                "controller": "account",
                "action": "getuserlogindata",
                "environment": environment,
                "identifier": refToken
            }).then((response) => {
                resolve(response.data.loginData);
            }).catch((errors) => {
                reject(errors);
            });
        })

    }

    const GetADRefToken = () => {
        var refToken = params.refToken;
        if (!refToken) {
            refToken = GetAuth0TokenLocal();
        }
        return refToken;
    }
  
    const GetSocialConnections = () => {
        return new Promise((resolve, reject) => {
            resolve([]);
        });
    }

    const CheckEmail = (email) => {
        return new Promise((resolve, reject) => {
            var bodyFormData = new FormData();
            bodyFormData.append('email', email);
            APIPost({
                "controller": "account",
                "action": "checkemail",
                "environment": environment,
                data: bodyFormData,
            }).then((response) => {
                if (response.data.inuse) {
                    resolve({ status: "email_found" });
                }
                else {
                    resolve({ status: "email_not_found" });
                }
            }).catch((error) => {
                reject(error);
            });
        });         
    }

    const CheckRegisteredAccount = (email) => {
        return new Promise((resolve, reject) => {
            var bodyFormData = new FormData();
            bodyFormData.append('email', email);
            APIPost({
                "controller": "account",
                "action": "checkregisteredaccount",
                "environment": environment,
                data: bodyFormData,
            }).then((response) => {
                if (response.data.inuse) {
                    resolve({ status: "email_found" });
                }
                else {
                    resolve({ status: "email_not_found" });
                }
            }).catch((error) => {
                reject(error);
            });
        });
    }

    function Logout(returnUrl, refToken) {
        GetCookieName().then((cookieName) => {
            DeleteCookie(cookieName);
        });

        if (refToken) {
            RemoveADToken();
        }
        
        if (!returnUrl) {
            returnUrl = window.location.origin;
        }
       
        var callBack = window.location.href;
        if (returnUrl) {
            callBack += "?ReturnUrl=" + returnUrl;
        }
        if (callBack.indexOf("?") > 0) {
            callBack += "&IsCallback=true";
        }
        else {
            callBack += "?IsCallback=true";
        }
        window.location.href=callBack;                    
    }

    const SendForgottenPasswordLink = (email) => {
        return new Promise((resolve, reject) => {
            var bodyFormData = new FormData();
            bodyFormData.append('email', email);

            APIPost({
                "controller": "account",
                "action": "sendforgottenpasswordlink",
                "environment": environment,
                data: bodyFormData,
            }).then((response) => {
                var result = response.data;
                if (!result.success) {
                    errors.push(ProcessErrors(result.errors));
                    reject(result.errors);
                }
                else {
                    resolve(result);
                }
            }).catch((axiosErrors) => {

                errors.push((ProcessErrors("error.unexpected")));
            });
        });
    }

    const ValidatePasswordResetToken = (token) => {
        return new Promise((resolve, reject) => {
            APIPost({
                "controller": "account",
                "action": "validateresetpasswordtoken",
                "environment": environment,
                "identifier": token
            }).then((response) => {
                var result = response.data;
                if (!result.success) {
                    if (errors.length == 0) {
                        result.errors.forEach((error) => {
                            errors.push(error);
                        });
                    }
                    reject(result);
                }
                else {
                    resolve(result);
                }
            }).catch((axiosErrors) => {
                errors.push("error.unexpected");
            });
        });
    }

    const ResetPassword = (token, newPassword) => {
        return new Promise((resolve, reject) => {
            var bodyFormData = new FormData();
            bodyFormData.append('newPassword', newPassword);
          
            APIPost({
                "controller": "account",
                "action": "resetpassword",
                "environment": environment,
                "identifier": token,
                data: bodyFormData
            }).then((response) => {
                var result = response.data;
                if (!result.success) {
                    errors.push(ProcessErrors(result.errors));
                    reject(result);
                }
                else {
                    resolve(result);
                }
            }).catch((errors) => {
                errors.push((ProcessErrors("error.unexpected")));
            });
        });
    }


    //if (!webAuth) {
        getWebAuth();
    //}

    if (isLoading) {
        getUserToken().then((userToken) => {
            setIsLoading(false);
            setUserData(userToken);

        }).catch((error) => {
            errors.push(ProcessErrors("error.unexpected"));
            setIsLoading(false);
        });
    }
    else {
        var sessionToken = GetADToken()
        if (!userData && sessionToken) {
            setUserData(sessionToken);
        }
    }
   
    return {
        authProviderType,
        environment,
        userData,
        isLoading,
        setErrors,
        errors,
        ReturnUrl,
        PassThruUrl,
        ForwardPage,
        CheckEmail,
        CheckRegisteredAccount,
        Login,
        Logout,
        GetADRefToken,
        GetSocialConnections,
        GetLoginData,
        ClearUserData,
        SendForgottenPasswordLink,
        ValidatePasswordResetToken,
        ResetPassword
    }
}

function ADNativeAuthenticationProvider(props) {
    const AuthContext = props.AuthContext;
    var auth = useProvideADNativeAuth();
    if (!auth || auth.isLoading) {
        return (<Loading loading={true} class={"center-column"} />);
    }
    else {
        return (<AuthContext.Provider value={auth} {...props} />)
    }
}

export {ADNativeAuthenticationProvider}