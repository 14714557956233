import React from 'react';
import './MessageBox.css';

//
const MessageBox = (props) => {
    return <div className={`message ${props.messageState}`}>
        <span class={`${props.messageState}-icon`}></span>
        <span > {props.children}</span>
    </div>
}

export default MessageBox;