import React, { useState, useEffect } from 'react';
import { SaveAuth0Token, RemoveAuth0Token, RemoveADToken, SaveADToken } from '../../Utilities/TokenManager';
import { APIGet, APIPost } from '../../API/APIRequest';
import { SaveCookie, DeleteCookie } from '../../Utilities/ADFECookie';

//function GetAuth0TokenFromServer(auth0Id) {
//    return new Promise((resolve, reject) => {
//        if (auth0Id) {
//            var bodyFormData = new FormData();
//            bodyFormData.append('auth0Id', auth0Id);

//            var accessTokenUrl = APIUrl({ "path": "/auth0/getaccesstoken" });
//            var auth0token = null;
//            axios({
//                method: "post",
//                url: accessTokenUrl,
//                data: bodyFormData,
//                headers: { "Content-Type": "multipart/form-data" },
//            }).then((response) => {
//                var result = response.data;
//                if (result.success) {
//                    if (result.token) {
//                        auth0token = {
//                            auth0id: auth0Id,
//                            token: result.token.value
//                        };
//                        SaveAuth0Token(auth0token);
//                    }
//                    else {
//                        RemoveAuth0Token();
//                    }
//                    resolve(auth0token);
//                }
//                else {
//                    RemoveAuth0Token();
//                    DeleteCookie();
//                    RemoveADToken();
//                    auth0token = null;
//                    reject(result.errors);
//                }
//            }).catch((error) => {
//                reject(error);
//            });
//        }
//    })
//};

function AutologinAPI(environment, auth0Token) {
    return new Promise((resolve, reject) => {
        if (auth0Token) {
            var bodyFormData = new FormData();
            bodyFormData.append('refToken', auth0Token);

            APIPost({
                "controller": "auth0",
                "action": "autologin",
                "environment": environment,
                data: bodyFormData
            }).then((response) => {
                var result = response.data;
                if (result.success) {
                    if (result.reftoken) {
                        SaveAuth0Token(result.reftoken);
                        var user = result.user;
                        SaveCookie(user.frontendCookieName, user.frontendCookieValue, user.frontendCookieDomain);
                        var userToken = {
                            "name": user.userfullname,
                            "token": user.userToken,
                            "partyid": user.partyid,
                            "username": user.username
                        };
                        SaveADToken(userToken);
                        resolve(userToken);
                    }
                    else {
                        RemoveAuth0Token();
                    }
                    resolve(result.user);
                }
                else {
                    RemoveAuth0Token();
                    DeleteCookie();
                    RemoveADToken();
                    reject(result.errors);
                }
            }).catch((error) => {
                reject(error);
            });
        }
    })
};

function GetAuth0UserByEmail(email, environment) {
    return new Promise((resolve, reject) => {
       
        APIGet({
            "controller": "auth0",
            "action": "getuserbyemail",
            "environment": environment,
            data: {
                "email": encodeURIComponent(email)
            }
        }).then((response) => {
            var result = response.data;
            if (result.success) {
                resolve(result.auth0response);
            }
            else {
                reject(result.errors);
            }
        }).
            catch((error) => {
                var unexpected = [];
                unexpected.push("error.unexpected");
                reject(unexpected);
            });
    });
}

function CreateADAuthenticationAssets(auth0Id) {
    return new Promise((resolve, reject) => {
        if (auth0Id) {
            var bodyFormData = new FormData();
            bodyFormData.append('additionalCodeType', 'Auth0_id');
            bodyFormData.append('additionalCodeValue', auth0Id);

            APIPost({
                "controller": "account",
                "action": "getlogindetailsbyadditionalcode",
                "environment": null,
                data: bodyFormData
            }).then((response) => {
                var result = response.data;
                if (result.success) {
                    var user = result.user;
                    SaveCookie(user.frontendCookieName, user.frontendCookieValue, user.frontendCookieDomain);
                    var userToken = {
                        "name": user.userfullname,
                        "token": user.userToken,
                        "partyid": user.partyid,
                        "username": user.username
                    };
                    SaveADToken(userToken);
                    resolve(userToken);
                }
                else {
                    reject(result.errors);
                }
            }).catch((error) => {
                reject(error);
            });    
        }   
    });
};

export {CreateADAuthenticationAssets,GetAuth0UserByEmail, AutologinAPI }

