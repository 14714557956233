import React, { useState, useRef, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom'
import { format } from 'react-string-format';
import { useTranslation } from 'react-i18next';
import Button from '../../UI/Buttons/Button';
import '../../../i18n';
import './UpdatePassword.css';
import { GetConfiguration } from '../../Configuration/ConfigurationManager'
import { APIGet, APIPost } from '../../API/APIRequest'
import { ErrorBox, ProcessErrors } from '../../Utilities/ErrorBox'
import { LogWebActivity } from '../../Webactivity/LogWebActivity';
import { Loading } from '../../Utilities/Loading'
import { IsSafeUrl, RedirectWarning } from '../../Utilities/SafeUrl'
import ErrorBoxCS from '../../UI/ErrorBox/ErrorBox';
import { useAuth } from '../AuthenticationProvider'
import Input from '../../UI/Inputs/Input';
import InputWrapper from '../../UI/Inputs/InputWrapper';
import { GetAuth0UserByEmail } from '../Auth0/Auth0ADInterface';
import { Password } from '../PasswordValidations/PasswordValidations';
import InputWrapperRefacture from '../../UI/Inputs/InputWrapperRefacture';
import useCreateUrl from '../../../Hooks/useCreateUrl';
import { GenericErrorBox } from '../../UI/ErrorBox/GenericErrorBox';
import setStateAsync from '../../Utilities/SetStateAsync';
import useBrandingData from '../../../Hooks/useBrandingData';
import { emailRegexUtils } from '../../Utilities/EmailRegex';
import Transition from '../../UI/Transition/Transition'; 
import usePageTitle from '../../../Hooks/usePageTitle';

/*-------------------update password-------------------------------------------------*/

export function UpdatePassword(props) {

    const { t, i18n } = useTranslation();
    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isNotTrusted, setIsNotTrusted] = useState('');
    const [loginData, setLoginData] = useState(null);
    const { environment } = useParams();
    const auth = useAuth();
    const headerRef = useRef(null);
    const [buttonEvent, setButtonEvent] = useState(false);
    const [fieldChanged, setFieldChanged] = useState(null);
    const [oldpassword, setOldPassword] = useState("");
    const [newpassword, setNewPassword] = useState("");
    const [pswValid, setPswValid] = useState("false");
    const [combNotMatches, setCombNotMatches] = useState(false);
    const [buttonState, setButtonState] = useState("initial");
    const createUrl = useCreateUrl();
    const formRef = useRef(null);
    const [IsBlank, setIsBlank] = useState({ newpassword: false, oldpassword: false });
    const [updateState, setUpdateState] = useState("not_started");   //state for handling password updation states
    const emailRegex = emailRegexUtils;
    usePageTitle(`Update password - ${props.brandingElement?.id}`);


    const GotoReturnUrl = () => {
        let returnUrl = auth.ReturnUrl();
        IsSafeUrl(returnUrl, environment).then((result) => {
            if (result) {

                // add adref token to returnUrl if request is from outside this host

                window.location.href = returnUrl;
            }
            else {
                if (!isNotTrusted) {
                    setIsNotTrusted(returnUrl);
                }
            }
        }).catch((errors) => {
            setErrors(ProcessErrors(errors));
        });
    }


    //componentDidMount
    useEffect(() => {

        if (!loginData && isLoading) {
            auth.GetLoginData(environment, auth.GetADRefToken()).then((response) => {
                setLoginData(response);
                setIsLoading(false);
            }).catch((errors) => {
                setErrors(ProcessErrors(errors));
                setIsLoading(false);
            })
        }
      headerRef.current?.focus();  //focusing on the module heading as we land on the page and the component mounts

    }, [])


    const updateUser = (event) => {

        event.preventDefault();
        setErrors(null);
        if (Validated()) {

            setButtonState("loading");
            let controller = "";

            switch (auth.authProviderType) {
                case "adnative":
                    controller = "account";
                    break;
                case "auth0":
                    controller = "auth0";
                    break;
            }
            let refToken = auth.GetADRefToken();
            let returnUrl = auth.ReturnUrl();
            let bodyFormData = new FormData();

            bodyFormData.append('password', oldpassword);
            bodyFormData.append('newPassword', newpassword);
            bodyFormData.append('returnUrl', returnUrl);
            APIPost({
                "controller": controller,
                "action": "updatepassword",
                "environment": environment,
                "identifier": refToken,
                data: bodyFormData,
            }).then((response) => {

                let updateAccountResult = response.data;
                if (updateAccountResult.success) {
                    //let apiUrl = APIUrl({ "path": "/auth0/authenticate" });
                    //apiUrl += "?ReturnUrl=" + returnUrl + "&email=" + result.auth0user.email;
                    //window.location.href = apiUrl;

                    setUpdateState("updated")
                    setTimeout(() => {
                        GotoReturnUrl();
                    }, 2000)

                    console.log(updateState)
                }
                else {
                    setErrors(ProcessErrors(updateAccountResult.errors));
                    setButtonState("initial");
                }
            }).
            catch((error) => {

                setErrors(ProcessErrors("error.unexpected"));
                setButtonState("initial");
            });
            
        }
    }


    const onTextFieldKeyed = (event) => {
        if ((event.key.match(emailRegex) || []).length > 0) {
            event.preventDefault();
        }
    }

    //password input-handler
    const onPasswordChanged = (event, type) => {
        let oldpassword = event.target.value;
        if (type === "old") {
            setOldPassword(oldpassword)
        }

    }

    const handleOnBlur = (e, fieldType) => {
        if (buttonEvent && fieldType === "oldpassword") {
            document.getElementById("oldpassword").focus();
            setButtonEvent(false);
            e.preventDefault();

        }

        if (buttonEvent && fieldType === "newpassword") {
            document.getElementById("newpassword").focus();
            setButtonEvent(false);
            e.preventDefault();

        }
        //due to handleOnBlur and the order of event-handlers
        setStateAsync(() => {
            let value = e.target.value;
            if (value.length < 1) {
                setIsBlank(IsBlank => ({ ...IsBlank, [fieldType]: true }))
            }
            else {
                setIsBlank(IsBlank => ({ ...IsBlank, [fieldType]: false }))
            }
        })


    }

    const Validated = () => {

        let validated = true;
        setIsBlank(IsBlank => ({ ...IsBlank, newpassword: false, oldpassword: false }))
        if (oldpassword < 1) {
            setIsBlank(IsBlank => ({ ...IsBlank, oldpassword: true }))
            validated &= false;
        }

        if (newpassword < 1) {
            setIsBlank(IsBlank => ({ ...IsBlank, newpassword: true }))
            validated &= false
        }
        if (pswValid !== "true") {
            validated &= false
        }

        if (!validated) {
            focusOnFirstError();
        }
      return validated;
    }

    //onSubmit focusing on first error causing input
    const focusOnFirstError = () => {
        setTimeout(() => {
            let firstElement = formRef.current?.querySelectorAll("input[invalidclass='Invalid-input']")[0]
            firstElement?.focus();
        }, 200)

    }

    let changePasswordText = format(t('updatepassword.summary'));

    const getContent = () => {

        if (loginData) {

            if (!`${loginData.user_id}`.startsWith("auth") && auth.authProviderType=="auth0") {
                return <div className="item-wrapper">
                    <p> It is not possible to change your password as you logged in with your
                        {`${loginData.user_id}`.startsWith("goo") && <strong> Google </strong>}
                        {`${loginData.user_id}`.startsWith("fac") && <strong> Facebook </strong>}
                        account.
                    </p>
                </div>
            } else {
                return (<form ref={formRef} onSubmit={updateUser} className={isNotTrusted ? "noshow" : ""}>
                    <div className="item-container">
                        <div className="item-wrapper">

                            <p>{changePasswordText} <strong>{loginData?.email}</strong></p>
                        </div>
                    </div>
                    {updateState === "not_started" ? <>
                   
                        <div className="item-container">
                            <div className="item-wrapper">
                                <InputWrapperRefacture label={t('updatepassword.currentpasswordlabel')}
                                    id="oldpassword"
                                    value={oldpassword}
                                    inputFieldType="password"
                                    inputSize="full-width"
                                    setButtonEvent={setButtonEvent}
                                    specs="password_input_with_button"
                                    showBtnText={t('input.showpasswordlabel')}
                                    hideBtnText={t('input.hidepasswordlabel')}
                                    invalidClass={(IsBlank.oldpassword) ? "Invalid-input" : ""}
                                    name="oldpassword"
                                    onBlur={(e) => { handleOnBlur(e, "oldpassword") }}
                                    onChange={(e) => onPasswordChanged(e, "old")}
                                    aria-invalid={IsBlank.oldpassword}
                                    aria-describedby="oldpassword-blank-error"
                                />
                                <span role="alert" id="oldpassword-blank-error">
                                    {/* {combNotMatches && <ErrorBoxCS>{t('login.combinationdoesnotmatch')}</ErrorBoxCS>} */}
                                    {IsBlank.oldpassword && <ErrorBoxCS>{t('updatepassword.blankoldpassword')}</ErrorBoxCS>}
                                </span>
                            </div>
                        </div>
                      
                        {/* password with validations --component */}
                      
                        <div className="item-container">
                            <div className="item-wrapper">
                                <Password label={t('updatepassword.passwordlabel')}
                                    IsBlank={IsBlank}
                                    setIsBlank={setIsBlank}
                                    newpassword={newpassword}
                                    setUserPassword={setNewPassword}
                                    pswValid={pswValid}
                                    setPswValid={setPswValid}
                                    name="newpassword" />
                            </div>
                        </div>
                    
                        
                        <div className="item-wrapper">
                            {buttonState === "initial" ?
                                <Button type="button" buttonSize="full-width">{t('updatepassword.updatebutton')}</Button>
                                : buttonState === "loading" &&
                                <Button type="button" isDisabled="true" buttonSize="full-width" hasIcon="updating">{t('updatepassword.updatingbutton')}</Button>
                            }
                        </div>
                    </>
                        : updateState === "updated" && <>
                        
                            <div className="item-container">
                                <div className="item-wrapper">
                                    <p> {t('updatepassword.redirecttext')} <a onClick={GotoReturnUrl} href="JavaScript:void(0)"> {t('updatepassword.backtoaccount')} </a></p>
                                </div>
                            </div>
                          
                            <div className="item-wrapper">
                                <Button type="button" isDisabled="true" buttonSize="full-width" hasIcon="updated" >{t('updatepassword.updatedbutton')}</Button>
                            </div>
                        </>

                    }

                </form>)

                if (loginData?.identities[0].provider !== "auth0") {
                    let actionMessage = format(t('updatepassword.backlinktext'), auth.ReturnUrl);
                    return <div><p>{t('error.socialdetailsupdateattempt')}</p><p><a href="JavaScript:void(0)" onClick={GotoReturnUrl}>{actionMessage}</a></p></div>
                }
            }
        }
        else if (!isLoading) {
            if (!errors || errors.length == 0) {
                let actionMessage = format(t('updatepassword.backlinktext'), auth.ReturnUrl);
                setErrors(ProcessErrors("error.usernotloggedin"));
            }
        }
    }

    var returnUrl = auth.ReturnUrl();
    if (!returnUrl && !isLoading) {
        if (errors.length == 0) {
            errors.push('error.noreturnurl');
        }
    }

    return (
        <div className="container" aria-live="polite">

            <div className="item-container" >
                <a onClick={GotoReturnUrl} href="JavaScript:void(0)" className="back-button flex-direction-row">
                    <span className="back-icon">
                    </span><p>{t('updateemail.backlinktext')}</p>
                </a>
            </div>
            <div className="item-container">
                {/*to make the screen reader read out the module heading we explicitly make it focus on it as the mounting happens*/}
                <h1 className="module-heading focusableHeader" tabIndex="-1" ref={headerRef}>{t('updatepassword.title')}</h1>
            </div>
            <span aria-live="polite"><GenericErrorBox errors={errors}/></span>
            <Loading loading={isLoading} />
            <RedirectWarning isNotTrusted={isNotTrusted} returnUrl={isNotTrusted} />
            <div className="item-wrapper">
                {getContent()}
            </div>
            <LogWebActivity />

        </div>
    );
}

