import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import '../../../../i18n';
import Transition from '../../../UI/Transition/Transition';
import './SocialConnections.css';

export function SocialConnections(props) {
    const { t, i18n } = useTranslation();

    console.log(props.connections);
    console.log(props.onclick)
   
    const getDisplayData = () => {
        return (
            props.connections.map((connection) => (
                <Transition timer={100} containerClass="social-wrapper" mountAnimation="mount-heightAnimation">
                <a id={connection.name} href="#" className="ghost-button full-width" value={connection.name} onClick={props.onclick} data-connection={connection.name}> 
                    <span className={connection.strategy} data-connection={connection.name}>{t('social.' + connection.strategy)}
                        {connection.strategy == 'facebook' ? <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.75 8C15.75 3.71875 12.2812 0.25 8 0.25C3.71875 0.25 0.25 3.71875 0.25 8C0.25 11.875 3.0625 15.0938 6.78125 15.6562V10.25H4.8125V8H6.78125V6.3125C6.78125 4.375 7.9375 3.28125 9.6875 3.28125C10.5625 3.28125 11.4375 3.4375 11.4375 3.4375V5.34375H10.4688C9.5 5.34375 9.1875 5.9375 9.1875 6.5625V8H11.3438L11 10.25H9.1875V15.6562C12.9062 15.0938 15.75 11.875 15.75 8Z" fill="#222222" />
                        </svg> : ''}
                        {connection.strategy == 'google-oauth2' ? <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.625 8.1875C15.625 12.625 12.5938 15.75 8.125 15.75C3.8125 15.75 0.375 12.3125 0.375 8C0.375 3.71875 3.8125 0.25 8.125 0.25C10.1875 0.25 11.9688 1.03125 13.3125 2.28125L11.1875 4.3125C8.4375 1.65625 3.3125 3.65625 3.3125 8C3.3125 10.7188 5.46875 12.9062 8.125 12.9062C11.1875 12.9062 12.3438 10.7188 12.5 9.5625H8.125V6.90625H15.5C15.5625 7.3125 15.625 7.6875 15.625 8.1875Z" fill="#222222" />
                        </svg>
 : ''}
                    </span>
                </a> 
                </Transition>
            ))
        )      
    };
   
    var displayData = getDisplayData();
    console.log(displayData);
    return (
        <>
            {displayData}           
        </>
    );
}

