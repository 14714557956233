import { useEffect, useState } from 'react';
import { useAuth } from '../components/Authentication/AuthenticationProvider';


//we set the brand data in front-end through this.

export default function useBrandingData(brandData) {
     
    const [brandingData, setBranding] = useState(brandData); //brand data coming from backend


    useEffect(() => {
        setBranding(brandData);
    }, [brandData])

    //brandKey is the name of the brandElement here.
    const getBrandedHtml = (brandKey, value) => {
      if (brandData) {
            let tempText = brandingData[brandKey];
              if (brandKey == 'changeEmailText') {
                tempText = tempText?.replace(/##user_email##/g, value);   //replacing the user_email string with the user entered value on front-end dynamically
               }
         return <span dangerouslySetInnerHTML={{ __html: tempText }}></span>  //returning the html
        }
        else {
           <></>
        }
     }
     
     return { brandingData, getBrandedHtml };
}
