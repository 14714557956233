import { GetConfiguration } from '../Configuration/ConfigurationManager'
import { APIGet, APIPost, getEnvironment } from '../API/APIRequest';
export function SaveCookie(cookiename, cookievalue, domain) {
    GetConfiguration('userconfiguration').then((configuration) => {
        var expiresDays = configuration.frontendloginpersistencedays;
        var currentDate = new Date();
        var expiresDate = new Date(currentDate.setDate(currentDate.getDate() + expiresDays));

        var cookie = cookiename + "=" + cookievalue + ";Path=/;secure;expires=" + expiresDate.toISOString();
        if (window.location.hostname.endsWith(domain)) {
            cookie += ";domain=" + domain
        }
        document.cookie = cookie;
    }).catch((error) => {
        throw (error);
    });
};

export function GetCookieName() {
    return new Promise((resolve, reject) => {
        var cookieName = sessionStorage.getItem('ADFECookieName');
        if (cookieName) {
            resolve(cookieName);
        }
        var environment = getEnvironment();
        APIGet({
            "controller": "account",
            "action": "frontendcookiename",
            "environment" : environment
        }).then((response) => {
            var result = response.data;
            if (result.success) {
                cookieName = result.frontendcookiename;
                sessionStorage.setItem('ADFECookieName', cookieName);
                resolve(cookieName);
            }
            reject(null);
        }).catch((error) => {
            reject(error);
        });
      
    });  
}

export function GetCookieValue(cookiename) {
    let name = cookiename + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return null;
}

export function ValidateCookieValue(cookievalue) {
    return new Promise((resolve, reject) => {
        var data = new FormData()
        data.append('cookieValue', cookievalue);
        var environment = getEnvironment();
        APIPost({
            "controller": "account",
            "action": "validateadfrontendcookie",
            "environment": environment
        })
            .then((response) => {
                var result = response.data;
                if (result.success) {
                    resolve(result.user);
                }
                else {
                    reject(result.errors);
                }
            }).
            catch((error) => {
                reject(error);
            });
        
    });
}
export function DeleteCookie(name) {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;Path=/;';
};

