import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Loading } from '../Utilities/Loading';
 
import usePageTitle from '../../Hooks/usePageTitle';


export function Redirection(props) {
    usePageTitle(`Redirect processing - ${props.brandingElement?.id}`);

    const { state } = useLocation();
  
    useEffect(()=>{

        if(state){
            window.location.replace(state?.returnUrl);
        }
      

    },[state])
  

    return ( <Loading loading="true" /> )
}

