import { Fragment, useState, forwardRef } from "react";
import InputNew from "./Input.js";
import ErrorBox from '../ErrorBox/ErrorBox';
import './InputRe.css';
import { Link } from "react-router-dom";
//import { shouldProcessLinkClick } from "react-router-dom/dist/dom";
const InputWrapperRefacture = forwardRef((props, ref) => {

    const { description, edit, inputSize, text, label, btnText, optionalField, setButtonEvent, helpField, inputFieldType, route, value, onClickFunction } = props;


    const [showHelp, setShowHelp] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [editable, setEditable] = useState(false);
    const [inputType, setInputType] = useState();


    //basic text input with a label along with a standard subtext with an exception of email input box

    let inputWidth = () => {
        switch (inputSize) {
            case 'full-width':
                return "full-width"
            case 'large':
                return "large"
            case 'medium':
                return "medium"
            case 'small':
                return "small"
            default:
                return "large"
        }
    }

    const helpLinkHandler = (e) => {
        e.preventDefault();
        return setShowHelp(!showHelp);
    }

    const inputField = () => {
        return <div class="field-wrapper">
            <div className={`div-wrapper ${inputWidth()}  ${props.invalidClass ? props.invalidClass : (value?.length > 0) ? "filled" : ""}`}>
                <input className={`${inputWidth()}`} type="text" disabled={edit ? !editable : false} label={label}  {...props} />
                {route ? <Link to={route}>{btnText}</Link> : (edit || onClickFunction) ? <button type="button" onClick={() => { edit ? setEditable(true) : onClickFunction(null) }}>{btnText}</button> : ""}
            </div>
        </div>
    }

    const inputText = () => {
        return <div class="inputText-wrapper">
            {text}
        </div>
    }

    const checkboxField = () => {
        return <div className="field-container">
            <div class="field-wrapper">
                <input className={inputWidth()} type="checkbox" label={label}  {...props} />
            </div>
            {text && inputText()}
        </div>
    }

    const radioField = () => {
        return <div class="field-wrapper">
            <input className={inputWidth()} type="radio" label={label}  {...props} />
            {text && inputText()}
        </div>
    }

    //div-wrapper is the class enclosing the input field and the button

    const passwordField = () => {
        return <div class="field-wrapper">

            <div className={` div-wrapper ${props.invalidClass ? props.invalidClass : (value?.length > 0) ? "filled" : ""}`}>
                <input ref={ref} className={`${inputSize}`} no-outline label={label} type={showPassword ? "text" : "password"} {...props} inputType={showPassword ? "text" : "password"} />
                <button type='button' name="show-hide" onMouseDown={(e) => { setButtonEvent(true) }} onClick={(e) => {
                    setShowPassword(!showPassword);
                }}>{showPassword ? "Hide" : "Show"}</button>
            </div>

        </div>
    }


    const labelField = () => {
        return <div class="label-wrapper">
            <div class="label-text">
                <label className="label" htmlFor={props.id}> {props.label} </label>
                <p className="label-description">{description}</p>
                {/*not present in code this particular spec */}   {(props.specs === "search_input") && <p className="meta">Type first letters to find..</p>}
            </div>
            <div class="label-aid">
                {optionalField ? <div className="required-text">Optional |</div> : ""}
                {helpField ? <div className="help-text"> <a href="#" className="help-text-btn" onClick={helpLinkHandler}> <span className={!showHelp ? "question-icon" : "close-icon"} ></span> </a> </div> : ""}

            </div>
        </div>
    }



    return <>
        {(!label && !description && !optionalField && !helpField) ? !labelField() : labelField()}
        {inputFieldType === 'password' && passwordField()}
        {inputFieldType === 'checkbox' && checkboxField()}
        {inputFieldType === 'radio' && radioField()}

        {inputFieldType === 'text' && inputField()}

    </>


})
export default InputWrapperRefacture;