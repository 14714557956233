import React, { Component, Fragment, Suspense, useEffect, useLayoutEffect, useState } from 'react';
import ReactDom from 'react-dom';
import ReactHtmlParser from 'html-react-parser';
import { Routes, Route } from 'react-router-dom';
import './general.css'
import './normalize.css'
import './reset.css'
import Layout from './components/Layout';
import './i18n'
import PrivateRoute from './components/Utilities/PrivateRoute';
import { Payment } from './components/Order/Payment';
import { OrderComplete } from './components/Order/OrderComplete';
//import { StripeResponseHandler } from './components/Order/Payment/StripeResponseHandler';
import { Login } from './components/Authentication/Login'
import TagManager from 'react-gtm-module'
import { Logout } from './components/Authentication/Logout'
import { Auth0Callback } from './components/Authentication/Auth0/Auth0Callback'
import { Register } from './components/Authentication/Register/Register'
import { UpdateEmail } from './components/Authentication/UpdateEmail/UpdateEmail'
import { UpdatePassword} from './components/Authentication/UpdatePassword/UpdatePassword'
import { ForgottenPassword } from './components/Authentication/ForgottenPassword/ForgottenPassword'
import { WarningComponent } from './components/Authentication/WarningComponent/WarningComponent';
import { RefreshADToken } from './components/Utilities/TokenManager'
import { Loading } from './components/Utilities/Loading'
import { ErrorBox, ProcessErrors } from './components/Utilities/ErrorBox'
//import { ProductOffer } from './components/ProductOffer/ProductOffer';
import { useAuth } from './components/Authentication/AuthenticationProvider';
import DesignReview from './components/UI/DesignReview/DesignReview';
import HeadProperties from './components/Layout/Head/HeadProperties';
import { ResetPassword } from './components/Authentication/ResetPassword/ResetPassword';
import { ClearCache } from './components/Caching/ClearCache';
import { APIGet, APIPost , getEnvironment } from './components/API/APIRequest';
import { Redirection } from './components/Redirection/Redirection';
import { Test } from './components/Test/Test';
import {
    GoogleReCaptchaProvider,
    useGoogleReCaptcha
  } from 'react-google-recaptcha-v3';
import { DynamicScriptComponent } from './DynamicScriptComponent';
  



export default function App(props) {
    const [branding, setBranding] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [reCaptchaKey, setReCaptchaKey] = useState(null);
    const [metaData, setMetaData] = useState(null);
    

    const environment = getEnvironment();
    const auth = useAuth();


    const loadheaderBranding = async () => {
        await APIGet({
            "controller": "brand",
            "action": "getbranding",
            "environment": environment
        })
            .then(function (response) {
                // handle success
                setBranding(response.data.branding);
                if(response.data.branding){
                    if(response.data.branding?.reCAPTCHAkey )
                    {
                    setReCaptchaKey(response.data.branding?.reCAPTCHAkey)
                    }
                    else{
                    setReCaptchaKey("no_recaptcha")
                    }
                   } //this is also async..so may take time so directly pass in the function.
                htmlHeadScript(response.data.branding);
                cssHeadScript(response.data.branding);
                tagManager(response.data.branding);
                faviconCustom(response.data.branding)
                setIsLoading(false);
                console.log('app:');
                console.log(response.data);

            })
            .catch(function (error) {
                // handle error
                setReCaptchaKey("no_recaptcha")   //if recaptcha snippet throws an error.
                setIsLoading(false)
                console.log(error);
            })

    }

    const tagManager = (branding) => {
        if (branding && environment != "ad-frontend.abasoft.co.uk") {
            const tagManagerArgs = {
                gtmId: branding.gtmId

            }

            TagManager.initialize(tagManagerArgs)
        }
    }


    const htmlHeadScript = (br) => {
        const htmlHead = br.headHtml;
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML=htmlHead;
        const scripts = tempDiv.querySelectorAll('script');

        scripts.forEach((script)=>{
           script.remove();
        })

        const modifiedHtml= tempDiv.innerHTML;
        return document.head.insertAdjacentHTML('beforeend', modifiedHtml);
    }

    const cssHeadScript = (data) => {
        const inlinecssHead = `<style type="text/css">${data.cssData} </style>`;
        return document.head.insertAdjacentHTML('beforeend', inlinecssHead);
    }
     

    const faviconCustom = (data) => {
        const favString = data.favicon || "";
        const favicons = favString.split(',');

        favicons.map((favicon, i) => {
            const link = `<link rel="icon" href=${favicon}" />`;
            return document.head.insertAdjacentHTML('beforeend', link);
        });
    }

    
    useEffect(() => {
        RefreshADToken();
        loadheaderBranding();    
    }, [])
    
    return (
        <>
        <DynamicScriptComponent metaData={metaData} branding={branding} />
        <Suspense fallback={Loading}>
            <Layout auth={auth} brandingData={branding}>
                <Routes>
                    <Route exact path='/' element={<Login />} />
                    <Route path='/:fid' element={<Login />} />
                    {/*<Route path='/payment/:fid' element={<PrivateRoute><Payment /></PrivateRoute>} />                   */}
                    {/*<Route path='/ordercomplete/:fid' element={<PrivateRoute><OrderComplete /></PrivateRoute>} />*/}
                     <Route path='/login' element={<Login brandingElement={branding} />} />
                    <Route path='/:environment/login' element={<Login brandingLogin={branding} />} />
                    <Route path='/logout' element={<Logout brandingElement={branding} />} />
                    <Route path='/:environment/logout' element={<Logout brandingElement={branding} />} />
                    <Route path='/auth0callback' element={<Auth0Callback />} />
                    <Route path='/:environment/auth0callback' element={<Auth0Callback />} />
                    <Route path='/register' element={<Register brandingElement={branding} />} />
                    <Route path='/:environment/register' element={reCaptchaKey && <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey} scriptProps={{ async: true }}><Register hasRecaptcha={reCaptchaKey!=="no_recaptcha"} brandingElement={branding} /></GoogleReCaptchaProvider> } />
                    <Route path='/updateemail' element={<UpdateEmail brandingElement={branding} />} />
                    <Route path='/:environment/updateemail' element={<UpdateEmail brandingElement={branding}/>} />
                    <Route path='/updatepassword' element={<UpdatePassword brandingElement={branding}/>} />
                    <Route path='/:environment/updatepassword' element={<UpdatePassword brandingElement={branding}/>} />
                    <Route path='/forgottenpassword' element={<ForgottenPassword brandingElement={branding} />} />
                    <Route path='/:environment/forgottenpassword' element={<ForgottenPassword brandingElement={branding}/>} />
                    <Route path='/resetpassword' element={<ResetPassword brandingElement={branding} />} />
                    <Route path='/:environment/resetpassword' element={<ResetPassword brandingElement={branding} />} />
                    <Route path='/warning' element={<WarningComponent />} />
                    <Route path='/:environment/warning' element={<WarningComponent brandingElement={branding} />} />
                    <Route path='/clearcache' element={<ClearCache brandingElement={branding} />} />
                    <Route path='/:environment/clearcache' element={<ClearCache brandingElement={branding} />} />
                    <Route path='/:environment/designreview' element={<DesignReview />} />
                    <Route path='/test' element={<Test />} />
                     <Route path='/redirect' element={<Redirection brandingElement={branding} />} />
                </Routes>
            </Layout>
        </Suspense>
        </>

    );

}
