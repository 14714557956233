import React, { Component, useEffect, useRef, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import './ResetPassword.css';
import { format } from 'react-string-format';
import ErrorBoxCS from '../../UI/ErrorBox/ErrorBox';
import { GetConfiguration } from '../../Configuration/ConfigurationManager';
import Button from '../../UI/Buttons/Button';
import InputWrapper from '../../UI/Inputs/InputWrapper';
import { ErrorBox, ProcessErrors } from '../../Utilities/ErrorBox';
import { useAuth } from '../AuthenticationProvider'
import { Password } from '../PasswordValidations/PasswordValidations';
import { Link } from "react-router-dom";
import useCreateUrl from '../../../Hooks/useCreateUrl';
import { GenericErrorBox } from '../../UI/ErrorBox/GenericErrorBox';
import { create } from 'http-proxy-middleware/lib/path-rewriter';
import Transition from '../../UI/Transition/Transition';
import usePageTitle from '../../../Hooks/usePageTitle';
export function ResetPassword(props) {
    const { t, i18n } = useTranslation();
    const [sent, setSent] = useState(false);
    const [userPassword, setUserPassword] = useState("");
    const [pswValid, setPswValid] = useState("false");
    const [errors, setErrors] = useState([]);
    const [IsBlank, setIsBlank] = useState({ password: false });
    const [userEmail, setUserEmail] = useState("");
    const [buttonState, setButtonState] = useState("initial");
    const headerRef = useRef(null);
    const formRef = useRef(null);
    const auth = useAuth();
    const navigate = useNavigate();
    const { environment } = useParams();
    //  const { token } = useParams();
    // const params = (new URL(document.location)).searchParams;
    // const token = params.get("token");
    const [tokenValidated, setTokenValidated] = useState(false);
    const [updateState, setUpdateState] = useState("not_started");   //state for handling password updation states
    const [passwordReset, setPasswordReset] = useState(false);

    const createUrl = useCreateUrl();
    const search = useLocation().search;
    const token = new URLSearchParams(search).get('token');

    usePageTitle(`Reset Password - ${props.brandingElement?.id}`);

    useEffect(() => {
        if (!tokenValidated && errors.length == 0) {
            auth.ValidatePasswordResetToken(token).then((result) => {
                setTokenValidated(true);
                setUserEmail(result.email);
            }).catch((result) => {
                if (errors.length == 0) {
                    setErrors(ProcessErrors(auth.errors));
                }
            });
        }

        headerRef.current?.focus();
    }, [])


    const ResetPassword = (event) => {
        event.preventDefault();
        setErrors(null);
        if (Validated()) {
            setButtonState("loading")
            let password = userPassword;
            auth.ResetPassword(token, password).then((result) => {
                setUpdateState("updated");
                setTimeout(() => {
                    //addedd the query param email and auth0callback
                    const url = createUrl("login") + "&next=Lw==" + "&step=password" + "&email=" + encodeURIComponent(userEmail)
                    navigate(url);
                }, 2000)

                // auth.Login(userEmail, password).then((result) => {
                //     setPasswordReset(true);

                // }).catch(() => {
                //     errors.push(ProcessErrors(auth.errors));
                // })
            }).catch(() => {
                errors.push(ProcessErrors(auth.errors));
                setButtonState("initial")
            });
        }
    }

    let changePasswordText = format(t('updatepassword.summary'), userEmail);

    //validation of all fields enabling the continue buttom
    const Validated = () => {
        let validated = true;
        if (pswValid !== "true") {
            validated &= false
        }
        if (userPassword < 1) {
            setIsBlank(IsBlank => ({ ...IsBlank, password: true }))
            validated &= false
        }

        if (!validated) {
            focusOnFirstError();
        }
        return validated;
    }

    //on submit focus on first error
    const focusOnFirstError = () => {
        setTimeout(() => {
            let firstElement = formRef.current?.querySelectorAll("input[invalidclass='Invalid-input']")[0]
            firstElement?.focus();
        }, 200)

    }

    return (

        <div className="container" aria-live="polite">
            <div className="item-container">
                <div className="item-wrapper">
                    <h1 className="module-heading focusableHeader" tabIndex="-1" ref={headerRef}>{t('login.resetpasswordtitle')} </h1>
                </div>
            </div>
            <span aria-live="polite"><GenericErrorBox errors={errors} /></span>
            {tokenValidated && !passwordReset ? <>
                <div className="item-container">
                    <div className="auth-row">
                        <p>{t('resetpassword.instructionstext')} <strong>{userEmail}</strong> </p>
                    </div>
                </div>
                <form ref={formRef} onSubmit={ResetPassword}>
                    {updateState === "not_started" ? <>
                      
                            <div className="item-container">
                                <div className="item-wrapper">
                                    <Password label={t('updatepassword.passwordlabel')}
                                        IsBlank={IsBlank}
                                        setIsBlank={setIsBlank}
                                        password={userPassword}
                                        setUserPassword={setUserPassword}
                                        pswValid={pswValid}
                                        setPswValid={setPswValid} />
                                </div>
                            </div>
                       
                     
                            {buttonState === "initial" ?
                                <Button type="button" buttonSize="full-width" >{t('login.continuebutton')}</Button>
                                : buttonState === "loading" &&
                                <Button type="button" isDisabled="true" hasIcon="updating" buttonSize="full-width" >{t('updatepassword.updatingbutton')}</Button>
                            }
                      
                    </>
                        : updateState === "updated" && <>
                         
                                <div className="item-container">
                                    <p>{t('resetpassword.redirecttext')}  <Link to={createUrl("login")} >{t('resetpassword.gotosignin')} </Link> </p>
                                </div>
                       
                        
                                <Button type="button" isDisabled="true" hasIcon="updated" buttonSize="full-width" >{t('updatepassword.updatedbutton')}</Button>
  
                        </>
                    }
                </form>
            </>
                : ''
            }
            {/* {passwordReset ? <>
                    <p>{t('resetpassword.resetcomplete')}</p>
                </>
                    : ''
                } */}
        </div>
    )
}

