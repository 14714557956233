import { Component } from "react";
import { APIPost,getEnvironment } from '../API/APIRequest'


    //constructor(props) {
    //    super(props);
    //    this.getToken = this.getToken.bind(this);
    //    this.saveToken = this.saveToken.bind(this);
    //    this.refreshToken = this.refreshToken.bind(this);
    //}

export function GetADToken() {
    var tokenString = sessionStorage.getItem('adtoken');
    if (tokenString) {
        var userToken = JSON.parse(tokenString);
        return userToken;
    }
    return null;
}

export function RefreshADToken() {
    return new Promise((resolve, reject) => {
        var userToken = GetADToken();
        if (userToken) {
            var bodyFormData = new FormData();
            bodyFormData.append('adtoken', userToken.token);

            var environment = getEnvironment();
            APIPost({
                "controller": "account",
                "action": "validateandrefreshadtoken",
                "environment" : environment,
                data: bodyFormData
            }).then((response) => {
                var result = response.data;
                if (result.success) {
                    userToken.token = result.token;
                    SaveADToken(userToken);
                    resolve(userToken);
                }
                else {
                    RemoveADToken();
                    resolve(null);
                }
            });
            resolve(userToken);
        }
        resolve(null);
    });
    
}

export function SaveADToken(userToken) {
    sessionStorage.setItem('adtoken', JSON.stringify(userToken));
}

export function RemoveADToken() {
    sessionStorage.clear();
}

export function GetAuth0TokenLocal() {
    var tokenString = localStorage.getItem('auth0token');
    var auth0Token = null;
    if (tokenString) {
        auth0Token = JSON.parse(tokenString);
    }
    return auth0Token;
}

export function SaveAuth0Token(auth0Token) {
    localStorage.setItem('auth0token', JSON.stringify(auth0Token));
}

export function RemoveAuth0Token() {
    localStorage.removeItem('auth0token');
}