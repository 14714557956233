import React, { useState, useEffect } from 'react';
import {useParams} from 'react-router-dom'
import { GetADToken } from '../Utilities/TokenManager';
import { APIPost } from '../API/APIRequest'
import { ErrorBox } from '../Utilities/ErrorBox'
import { GenericErrorBox } from '../UI/ErrorBox/GenericErrorBox';

export function LogWebActivity(props) {
    
    const [logged, setLogged] = useState(false);
    const [errors, setErrors] = useState([]);
    const { environment } = useParams();

    useEffect (() => {
        logWebActivity();
    }, [])

    const logWebActivity = () => {
        if (!logged) {
            const data = new FormData();
            var token = GetADToken();

            data.append('item', window.location.href);
            data.append('userToken', JSON.stringify(token));

            APIPost({
                "controller": "WebActivity",
                "action": "SaveWebActivity",
                "environment": environment,
                data: data
                }).then((response) => {
                setLogged(true);
            }).
                catch((error) => {
                    var unexpected = [];
                    unexpected.push("error.unexpected");
                    setErrors(unexpected);
            });
           
        }
    }
    return (<GenericErrorBox errors={errors}/>);
}
