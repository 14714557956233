import { useTranslation } from 'react-i18next';
import '../../i18n'
import '../../general.css'
import ErrorBoxCS from '../UI/ErrorBox/ErrorBox';

export function RecaptchaError(props) {
    const { t, i18n } = useTranslation();
    return (
        <div className="container">
       {t('recaptcha.nothumanerror')}
        </div>
      );
}