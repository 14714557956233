import i18n from 'i18next'
import Backend from 'i18next-http-backend';
import ChainedBackend from 'i18next-chained-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'; // load from local storage
import { initReactI18next } from 'react-i18next';

import { APIGet, getEnvironment, APIUrl } from './components/API/APIRequest';

var language = sessionStorage.getItem('language');
const environment = getEnvironment();

var acn = 'getlanguagefilever/translations/en';

APIGet({
    "controller": "brand",
    "action": acn,
    "environment": environment
}).then((response) => {
    var result = response.data;
    console.log(result);
    if (result.success) {
        localStorage.setItem("i18next_ver_adfev2", result.langVer);
        console.log('local storage lver set');
    }
    else {
        console.log(result.errors);
    }
}).catch((error) => {
    console.log(error);
});

var lver = localStorage.getItem('i18next_ver_adfev2');
if (lver === null)
    lver = '20240101000000';

var lpathremote = APIUrl({
    "controller": "brand",
    "action": "getlanguagefile",
    "environment": environment,
});
lpathremote = lpathremote + '/{{ns}}/{{lng}}';

i18n
    .use(ChainedBackend)
    .use(initReactI18next)
    .init({
        lng: language,
        backend: {
            backends: [
                LocalStorageBackend,  // primary
                Backend  // fallback
            ],
            backendOptions: [{
                prefix: 'i18next_adfev2_',
                //expiration in ms (ie 7 days is 7*24*60*60*1000)
                expirationTime: 7*24*60*60*1000,
                defaultVersion: lver,
                // can be either window.localStorage or window.sessionStorage. Default: window.localStorage
                store: typeof window !== 'undefined' ? window.localStorage : null
            }, {
                loadPath: lpathremote // http api load path for my own fallback
            }]
        },
        fallbackLng: 'en',
        debug: true,
        ns: ['translations'],
        defaultNS: 'translations',
        keySeparator: false,
        interpolation: {
            escapeValue: false,
            formatSeparator: ','
        },
        react: {
            wait: true
        }
    });

export default i18n
