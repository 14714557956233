import React, { useState, useRef, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom'
import { format } from 'react-string-format';
import { useTranslation } from 'react-i18next';
import Button from '../../UI/Buttons/Button';
import '../../../i18n';
import './UpdateEmail.css';
import { GetConfiguration } from '../../Configuration/ConfigurationManager'
import { APIGet, APIPost } from '../../API/APIRequest'
import { ErrorBox, ProcessErrors } from '../../Utilities/ErrorBox'
import { LogWebActivity } from '../../Webactivity/LogWebActivity';
import { Loading } from '../../Utilities/Loading'
import { IsSafeUrl, RedirectWarning } from '../../Utilities/SafeUrl'
import ErrorBoxCS from '../../UI/ErrorBox/ErrorBox';
import { useAuth } from '../AuthenticationProvider'
import Input from '../../UI/Inputs/Input';
import InputWrapper from '../../UI/Inputs/InputWrapper';
import { GetAuth0UserByEmail } from '../Auth0/Auth0ADInterface';
import { Password } from '../PasswordValidations/PasswordValidations';
import InputWrapperRefacture from '../../UI/Inputs/InputWrapperRefacture';
import useCreateUrl from '../../../Hooks/useCreateUrl';
import { GenericErrorBox } from '../../UI/ErrorBox/GenericErrorBox';
import setStateAsync from '../../Utilities/SetStateAsync';
import useBrandingData from '../../../Hooks/useBrandingData';
import { emailRegexUtils } from '../../Utilities/EmailRegex';
import Transition from '../../UI/Transition/Transition'; 
import usePageTitle from '../../../Hooks/usePageTitle';




export function UpdateEmail(props) {

    const { t, i18n } = useTranslation();
    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isNotTrusted, setIsNotTrusted] = useState('');
    const [loginData, setLoginData] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [nextAction, setNextAction] = useState(null);
    const [IsEmailInvalid, setIsEmailInvalid] = useState(false);
    const [IsEmailBlank, setIsEmailBlank] = useState(false);
    const [buttonEvent, setButtonEvent] = useState(false);
    const [userPassword, setUserPassword] = useState(null);
    const [updateState, setUpdateState] = useState("not_started");
    const createUrl = useCreateUrl();
    const [combNotMatches, setCombNotMatches] = useState(false)
    const [IsBlank, setIsBlank] = useState({ email: false, password: false });
    const { environment } = useParams();
    const headerRef = useRef(null);
    const [buttonState, setButtonState] = useState("initial");
    const [changeEmailText, setChangeEmailText] = useState("");
    const auth = useAuth();
    const formRef = useRef(null);
    const [fieldChanged, setFieldChanged] = useState(null);
    const { getBrandedHtml } = useBrandingData(props.brandingElement ? props.brandingElement : "");

    usePageTitle(`Update email - ${props.brandingElement?.id}`);

    const emailRegex = emailRegexUtils;

    const GotoReturnUrl = () => {

        let returnUrl = auth.ReturnUrl();
        IsSafeUrl(returnUrl, environment).then((result) => {
            if (result) {

                // add adref token to returnUrl if request is from outside this host

                window.location.href = returnUrl;
                return returnUrl;
            }
            else {
                if (!isNotTrusted) {
                    setIsNotTrusted(returnUrl);
                }
            }
        }).catch((errors) => {
            setErrors(ProcessErrors(errors));
        });
    }


    const updateUser = (event) => {

        event.preventDefault();
        setErrors(null);
        if (Validated()) {
            setButtonState("loading")
            
            let controller = "";
            switch (auth.authProviderType) {
                case "adnative":
                    controller = "account";
                    break;
                case "auth0":
                    controller = "auth0";
                    break;
            }
            let refToken = auth.GetADRefToken();
               
            let bodyFormData = new FormData();

            bodyFormData.append('newEmail', userEmail);
            bodyFormData.append('password', userPassword);
            APIPost({
                "controller": controller,
                "action": "updateemail",
                "environment": environment,
                "identifier" : refToken,
                data: bodyFormData
            }).then((response) => {
                let updateAccountResult = response.data;
                if (updateAccountResult.success) {
                    setUpdateState("updated")
                    setTimeout(() => {
                        GotoReturnUrl();
                    }, 2000)

                }
                else {
                    setErrors(ProcessErrors(updateAccountResult.errors));
                    setButtonState("initial")
                }
            }).
            catch((error) => {
                setErrors(ProcessErrors("error.unexpected"));
                setButtonState("initial")
            });            
        }
    }

    const onTextFieldKeyed = (event) => {
        if ((event.key.match(emailRegex) || []).length > 0) {
            event.preventDefault();
        }
    }

    const onTextFieldChanged = (event) => {
        setFieldChanged(event.target.name + event.target.value);
    }



    const onPasswordChanged = (event) => {
        let password = event.target.value;
        setUserPassword(password)
        let updatedCounts = {};
        updatedCounts.length = password.length;
        updatedCounts.upper = (password.match(/[A-Z]/g) || []).length;
        updatedCounts.lower = (password.match(/[a-z]/g) || []).length;
        updatedCounts.digit = (password.match(/[0-9]/g) || []).length;
        updatedCounts.special = (password.match(/[^A-Za-z0-9]/g) || []).length;

    }


    //email address validation
    const Validated = () => {

        let validated = true;
        let emailValue = userEmail || "";
        let temp = emailValue?.split("@");  //for assessing the local part of domain
        let localPart = temp ? temp[0]: "";
        setIsBlank(IsBlank => ({ email: false, password: false }))

        if ((emailValue.length > 1) && (emailValue?.match(emailRegex) || []).length <= 0 || localPart.length > 64) {
            setIsEmailInvalid(true);
            validated &= false;

        }
        if (emailValue < 1) {
            setIsEmailInvalid(false);
            setIsBlank(IsBlank => ({ ...IsBlank, email: true }))

            validated &= false
        }

        if (userPassword < 1) {
            setIsBlank(IsBlank => ({ ...IsBlank, password: true }))
            validated &= false
        }
         
        if(!validated){
            focusOnFirstError();
            }
       
        return validated;

    }

    //onSubmit focusing on first error causing input
    const focusOnFirstError=()=>{
        setTimeout(()=>{
            let firstElement= formRef.current?.querySelectorAll("input[invalidclass='Invalid-input']")[0]
            firstElement?.focus();
         },200)
        
    }

    //componentDidMount
    useEffect(() => {
        if (!loginData && isLoading) {
            auth.GetLoginData(environment, auth.GetADRefToken()).then((response) => {
                setLoginData(response);
                setIsLoading(false);
            }).catch((errors) => {
                setErrors(ProcessErrors(errors));
                setIsLoading(false);
            })
        }

        headerRef.current?.focus();

    }, [])


    const handleOnBlur = (e, fieldType) => {

        let value = e.target.value;
        if (buttonEvent) {
            document.getElementById("password").focus();
            setButtonEvent(false)
            e.preventDefault();
        }
        
       //due to OnBlur
        setStateAsync(() => {
            if (value.length < 1) {
                setIsEmailInvalid(false);
                setIsBlank(IsBlank => ({ ...IsBlank, [fieldType]: true }))
            }
            else {
                setIsBlank(IsBlank => ({ ...IsBlank, [fieldType]: false }))
            }
        });


    }


    let changeEmailText1 = format(t('updateemail.summarypart1'));
    let changeEmailText2 = format(t('updateemail.summarypart2'));

    //    useEffect(()=>{
    //         let tempText=  props.brandingElement?.changeEmailText
    //         tempText = tempText?.replace(/##user_email##/g, loginData?.email );
    //         if(tempText){
    //         setChangeEmailText(tempText)
    //         }
    //      }, [props.brandingElement?.changeEmailText]) 



    const getContent = () => {
        let updateContent = '';

        if (loginData) {

            if (!`${loginData.user_id}`.startsWith("auth") && auth.authProviderType == "auth0") {

                return <div className="item-wrapper">
                    <p> It is not possible to change your email address as you logged in with your
                        {`${loginData.user_id}`.startsWith("goo") && <strong> Google </strong>}
                        {`${loginData.user_id}`.startsWith("fac") && <strong> Facebook </strong>}
                        account.
                    </p>
                </div>
            } else {
                return (<form ref={formRef} onSubmit={updateUser} className={isNotTrusted ? "noshow" : ""}>
                    <div className="item-container">
                        <div className="item-wrapper">
                            {props.brandingElement?.changeEmailText ? getBrandedHtml('changeEmailText', loginData?.email) : <p>{changeEmailText1} <strong>{loginData?.email}</strong> {changeEmailText2}</p>}
                        </div>
                    </div>
                    {(updateState === "not_started") ?
                        <> 
                            <div className="item-container">
                            <div className="item-wrapper">
                                <InputWrapperRefacture
                                    label={t('updateemail.emaillabel')}
                                    edit={false}
                                    id="email"
                                    name="email"
                                    type="email"
                                    inputFieldType="text"
                                    onBlur={(e) => { handleOnBlur(e, "email") }}
                                    btnText={t('input.edittextlabel')}
                                    inputSize="full-width"
                                    value={userEmail}
                                    invalidClass={(IsBlank.email || IsEmailInvalid) ? "Invalid-input" : ""}
                                    onKeyPress={onTextFieldKeyed}
                                    onChange={(e) => { setUserEmail(e.target.value) }}
                                    aria-invalid={(IsBlank.email || IsEmailInvalid)}
                                    aria-describedby="email-errors" />

                                <span role="alert" id="email-errors">
                                {IsBlank.email && <ErrorBoxCS>{t('updateemail.blanknewemail')}</ErrorBoxCS>}
                                {IsEmailInvalid ? props.brandingElement?.mailValidError ? <ErrorBoxCS> {getBrandedHtml('mailValidError')} </ErrorBoxCS> : <ErrorBoxCS>{t('login.incorrectemailinput')}</ErrorBoxCS> : ""}
                                </span>

                            </div>
                        </div>
             
                      
                            <div className="item-container">
                                <div className="item-wrapper">
                                    {/* <Input label={t('updateemail.currentpasswordlabel')} id="password" name="password" type="password" /> */}
                                    <InputWrapperRefacture
                                        label={t('updateemail.currentpasswordlabel')}
                                        id="password"
                                        value={userPassword}
                                        setButtonEvent={setButtonEvent}
                                        inputFieldType="password"
                                        onBlur={(e) => { handleOnBlur(e, "password") }}
                                        inputSize="full-width"
                                        showBtnText={t('input.showpasswordlabel')}
                                        hideBtnText={t('input.hidepasswordlabel')}
                                        name="password"
                                        invalidClass={(IsBlank.password) ? "Invalid-input" : ""}
                                        onChange={onPasswordChanged} 
                                        aria-invalid={IsBlank.password}
                                        aria-describedby="password-blank-error"/>

                                    <span role="alert" id="password-blank-error">
                                    {IsBlank.password && <ErrorBoxCS>{t('updateemail.blankoldpassword')}</ErrorBoxCS>}
                                    </span>

                                    <Link className="link" to={createUrl("forgottenpassword")} state={{ from: "change-email" }}><p>{t('forgottenpassword.linktext')}</p></Link>
                                </div>

                            </div>
                         

                           
                            <div className="item-wrapper">
                                {buttonState === "initial" ?
                                    <Button type="button" buttonSize="full-width" >{t('updateemail.updatebutton')}</Button>
                                    : buttonState === "loading" &&
                                    <Button buttonSize="full-width" isDisabled="true" hasIcon="updating">{t('updateemail.updatingbutton')}</Button>
                                }
                            </div>
                        </>
                        : (updateState === "updated") && <>
                       
                            <div className="item-container">
                                <div className="item-wrapper">
                                    <p>{t('updatepassword.redirecttext')}  <a onClick={GotoReturnUrl} href="JavaScript:void(0)"> {t('updatepassword.backtoaccount')} </a></p>{/*has to be changed to backToaccount*/}
                                </div>
                            </div>
                       

                            <div className="item-wrapper">
                                <Button buttonSize="full-width" isDisabled="true" hasIcon="updated">{t('updateemail.updatedbutton')}</Button>
                            </div>
                        </>
                    }
                </form>
                )
                if (loginData?.identities[0].provider !== "auth0") {
                    let actionMessage = format(t('updateemail.backlinktext'), auth.ReturnUrl);
                    updateContent = <div><p>{t('error.socialdetailsupdateattempt')}</p><p><a href="#" onClick={GotoReturnUrl}>{actionMessage}</a></p></div>
                }
            }


        }
        else if (!isLoading) {
            if (!errors || errors.length == 0) {
                let actionMessage = format(t('updateemail.backlinktext'), auth.ReturnUrl);
                setErrors(ProcessErrors("error.usernotloggedin"));
            }
        }

    }
    var returnUrl = auth.ReturnUrl();
    if (!returnUrl && !isLoading) {
        if (errors.length == 0) {
            errors.push('error.noreturnurl');
        }
    }


    return (
        <div class="container" aria-live="polite">
            <div className="item-container">
                <a href="JavaScript:void(0)" onClick={GotoReturnUrl} className="back-button flex-direction-row">
                    <span className="back-icon"></span>
                    <p>{t('updateemail.backlinktext')}</p>
                </a>
            </div>
            <div className="item-container">
                {/*to make the screen reader read out the module heading we explicitly make it focus on it as the mounting happens*/}
                <h1 className="module-heading focusableHeader" tabIndex="-1" ref={headerRef}>{t('updateemail.title')}</h1>
            </div>
            <span aria-live="polite"><GenericErrorBox errors={errors}/></span>
            <Loading loading={isLoading} />
            <RedirectWarning isNotTrusted={isNotTrusted} returnUrl={isNotTrusted} />
            {getContent()}
            <LogWebActivity />

        </div>
    );
}


