import React, { Component, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import { Link } from 'react-router-dom'
import ErrorBoxCS from '../../UI/ErrorBox/ErrorBox';
import './ForgottenPassword.css';
import Button from '../../UI/Buttons/Button';
import { format } from 'react-string-format';
import InputWrapper from '../../UI/Inputs/InputWrapper';
import { ErrorBox, ProcessErrors } from '../../Utilities/ErrorBox';
import { useAuth } from '../AuthenticationProvider'
import InputWrapperRefacture from '../../UI/Inputs/InputWrapperRefacture';
import useCreateUrl from '../../../Hooks/useCreateUrl';
import useBodyClass from '../../../Hooks/useBodyClass';
import usePageTitle from '../../../Hooks/usePageTitle';
import { GenericErrorBox } from '../../UI/ErrorBox/GenericErrorBox';
import { useLocation, useParams } from 'react-router-dom';
import { IsSafeUrl, RedirectWarning } from '../../Utilities/SafeUrl';
import useBrandingData from '../../../Hooks/useBrandingData';
import { emailRegexUtils } from '../../Utilities/EmailRegex';
import Transition from '../../UI/Transition/Transition';



export function ForgottenPassword(props) {
    const { t, i18n } = useTranslation();
    const { environment } = useParams();
    const [sent, setSent] = useState(false);
    const [errors, setErrors] = useState([]);
    const [isNotTrusted, setIsNotTrusted] = useState('');
    const [errorState, setErrorState] = useState(false)
    const [userEmail, setUserEmail] = useState("");
    const [IsEmailInvalid, setIsEmailInvalid] = useState(false);
    const [IsEmailBlank, setIsEmailBlank] = useState(false);
    const [buttonState, setButtonState] = useState("initial");
    const location = useLocation();
    const formRef = useRef(null);
    const headerRef = useRef(null);
    const { from } = location.state ? location.state : "";
    const { getBrandedHtml } = useBrandingData(props.brandingElement ? props.brandingElement : "");
    const createUrl = useCreateUrl();

    console.log(from);
    const auth = useAuth();

    const checkEmail = (event) => {
        event.preventDefault();
        setErrors(null);
        let bodyFormData = new FormData();
        let email = userEmail.toLowerCase();

        if (emailInputIsValid()) {

            setButtonState("loading");
            auth.CheckRegisteredAccount(email).then((response) => {
                if (response.status === "email_found") {
                    auth.SendForgottenPasswordLink(email).then((response) => {
                        setSent(true);
                    }).catch((error) => {
                        // setErrors(ProcessErrors("error.notsent"));
                        setSent(true);
                    });
                } else {
                    setSent(true);
                }
            }).catch((error) => {
                // setErrors(ProcessErrors("error.unexpected"));
                setSent(true);
            });
        }
    }

    useBodyClass(['forgotten-password', props.brandingElement?.bodyClass]);
    usePageTitle(`Forgotten Password - ${props.brandingElement?.id}`);


    const emailRegex = emailRegexUtils;

    const Validated = () => {
        let validated = true;
        let emailValue = userEmail || "";
        let temp = emailValue?.split("@");
        let localPart = temp ? temp[0] : "";
        if ((emailValue?.match(emailRegex) || []).length <= 0 || localPart.length > 64) {
            validated = false;
        }

        if (!validated) {
            focusOnFirstError();
        }

        return validated;
    }

    const GotoReturnUrl = () => {
        let returnUrl = auth.ReturnUrl();
        IsSafeUrl(returnUrl, environment).then((result) => {
            if (result) {

                // add adref token to returnUrl if request is from outside this host

                window.location.href = returnUrl;
            }
            else {
                if (!isNotTrusted) {
                    setIsNotTrusted(returnUrl);
                }
            }
        }).catch((errors) => {
            setErrors(ProcessErrors(errors));
        });
    }

    //for managing error-message-states
    const emailInputIsValid = () => {

        setIsEmailBlank(false)
        setIsEmailInvalid(false);
        let isValid = Validated();

        if (userEmail == "") {
            setIsEmailBlank(true)
            return false;
        }
        else if (!isValid) {
            setIsEmailInvalid(true);
            return false;
        }
        else {
            return true;
        }

    }

    //onSubmit focusing on first error causing input
    const focusOnFirstError = () => {
        setTimeout(() => {
            let firstElement = formRef.current?.querySelectorAll("input[invalidclass='Invalid-input']")[0]
            firstElement?.focus();
        }, 200)

    }

    //componentDidMount
    useEffect(() => {
        headerRef.current?.focus();
    }, [])

    return (

        <div className="container" aria-live="polite">

            <div className="item-container">
                {from === "change-email" ? <a href="javascript:void(0)" onClick={GotoReturnUrl} className="back-button flex-direction-row">
                    <span className="back-icon">
                    </span><p>{t('updateemail.backlinktext')}</p>
                </a>
                    :
                    <Link to={createUrl("login")} className="back-button">
                        <span className="back-icon"> </span>
                        <span>{t('forgottenpassword.backlinktext')}</span>
                    </Link>}
            </div>


            <div className="item-container" >
                <div className="item-wrapper" >

                    <h1 className="module-heading focusableHeader" tabIndex="-1" ref={headerRef} > {t('login.forgottenpasswordtitle')} </h1>
                    {!sent && <p>{t('forgottenpassword.instructionstext')}</p>}
                </div>
            </div>

            <span aria-live="polite"><GenericErrorBox errors={errors} /></span>
            {!sent ? <>

                <form ref={formRef} onSubmit={checkEmail}>
                   
                        <div className="item-container">
                            <div className="item-wrapper">
                                <InputWrapperRefacture
                                    label={t('login.emaillabel')}
                                    specs="text_input"
                                    id="email"
                                    name="email"
                                    placeholder={t('placeholder.email')}
                                    inputFieldType="text"
                                    inputSize="full-width"
                                    type="email"
                                    value={userEmail}
                                    invalidClass={(errorState || IsEmailBlank || IsEmailInvalid) ? "Invalid-input" : ""}
                                    aria-invalid={(errorState || IsEmailBlank || IsEmailInvalid)}
                                    aria-describedby="email-errors"
                                    onChange={(e) => { setUserEmail(e.target.value) }} />
                            </div>
                            <span role="alert" id="email-errors">
                                {IsEmailBlank && <ErrorBoxCS>{t('login.blankemailinput')}</ErrorBoxCS>}
                                {IsEmailInvalid ? props.brandingElement?.mailValidError ? <ErrorBoxCS> {getBrandedHtml('mailValidError')} </ErrorBoxCS> : <ErrorBoxCS>{t('login.incorrectemailinput')}</ErrorBoxCS> : ""}
                            </span>
                        </div>
             
                  
                        {buttonState === "initial" ?
                            <Button buttonSize="full-width">{t('login.continuebutton')}</Button>
                            : buttonState === "loading" &&
                            <Button isDisabled="true" hasIcon="updating" buttonSize="full-width">{t('login.continuebutton')}</Button>
                        }
            
                </form>


            </> :
              
                    <div className="item-container">
                        <div className="item-wrapper">
                            <p>{(t('forgottenpassword.emailsentresponse1'))}<strong>{userEmail}</strong>{(t('forgottenpassword.emailsentresponse2'))}</p>
                        </div>
                        <div className="item-wrapper">
                            <p>{t('forgottenpassword.emailsentsubtext')}
                                <a href="JavaScript:void(0)" onClick={(e) => { e.preventDefault(); setSent(false); setButtonState("initial"); }} >{t('forgottenpassword.emailreentertext')} </a>
                            </p>
                        </div>
                    </div>
        
            }

        </div>

    )
}

