import React, { Component, useState, useEffect } from 'react';
import { APIGet, APIPost } from '../API/APIRequest';
export function Test(props) {

    APIGet({
        "controller": "security",
        "action": "test",
        "data": {
            "data1": "GET data1",
            "data2": "GET data2"
        },
        "environment": null
    }).then((response) => { console.log(response) })
        .catch((error) => { console.log(error) });

    var data = new FormData();
    data.append("data1", "POST data1");
    data.append("data2", "POST data2");
    APIPost({
        "controller": "security",
        "action": "test",
        "data": data,
        "environment": null
    }).then((response) => { console.log(response) })
        .catch((error) => { console.log(error) });
    return (
        <div>
            Hello World
        </div>
    )
  
}
