
import ButtonSvg from "./ButtonSvg";
import classes from './Button.module.css';
import './Button.css';
import ButtonSvgDark from "./ButtonSvgDark";
import ButtonSvgDisabled from "./ButtonSvgDisabled";
import UpdatedButton from "./UpdatedButton";
import UpdatingButton from "./UpdatingButton";

const Button = props => {
   
   let buttonType = () => {
      switch (props.btnType) {
         case 'primary':
            return "primary-button"
         case 'secondary':
            return "secondary-button"
         case 'ghost':
            return "ghost-button"
         case 'tierchiary':
            return 'tierchiary-button'
         default:
            return "primary-button"
      }
   }

   let btnSize = () => {
       switch (props.buttonSize) {
           case 'full-width':
               return "full-width"
         case 'large':
            return "large"
         case 'medium':
            return "medium"
         case 'small':
            return "small"
         default:
            return "large"
      }
   }
   if (props.isDisabled) {
      <ButtonSvgDisabled />
   }  


   return <button disabled={props.isDisabled === 'true' ? "disabled" : ''} 
   className={`
   ${buttonType()}
   ${props.hasIcon === 'true' ? "hasIcon" : ''}
   ${props.isDisabled === 'true' ? "disabled" : '' }
   ${props.hasOnlyIcon === 'true' ? "hasOnlyIcon" : ''}
   ${btnSize()}
    `}
       {...props.children}>

       {props.children}

        <span>{props?.txtName}</span>
        {/* {props.hasIcon === "true" && <span className={classes.buttonIcon}>  {props.btnType === 'secondary' ?   <ButtonSvgDark />  : <ButtonSvg /> } </span>} */}
        {props.hasIcon === "updated" && <span className={classes.buttonIcon}>  {props.btnType === 'secondary' ?   <UpdatedButton />  : <UpdatedButton /> } </span>}
        {props.hasIcon === "updating" && <span className={classes.buttonIcon}>  {props.btnType === 'secondary' ?   <UpdatingButton />  :  <UpdatingButton />} </span>}
       {props.hasOnlyIcon === "true" && <span className={classes.buttonIcon}> {props.btnType === 'secondary' ? <ButtonSvgDark /> : <ButtonSvg />} </span>}
   </button>
}
export default Button;