import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../../i18n'
import { GetConfiguration } from '../Configuration/ConfigurationManager'
import { ErrorBox, ProcessErrors } from '../Utilities/ErrorBox'
import { useAuth } from './AuthenticationProvider'
import { LoginAuth } from './Login/LoginAuth'
import { GenericErrorBox } from '../UI/ErrorBox/GenericErrorBox';
import usePageTitle from '../../Hooks/usePageTitle'
export function Login(props) {
    const { t, i18n } = useTranslation();
    const [providerType, setProviderType] = useState('none');
    const [NotFound, setNotFound] = useState(false);
    const [errors, setErrors] = useState([]);
    //const [returnUrl, setReturnUrl] = useState(null);
    const auth = useAuth();
    usePageTitle(`Sign in or create an account - ${props?.brandingLogin?.id}`);
    var returnUrl = auth.ReturnUrl();
    if (!returnUrl) {
        if (errors.length == 0) {
            errors.push('error.noreturnurl');
        }
        return (<GenericErrorBox errors={errors} />)
    }
  
    return (<LoginAuth brandingElement={props.brandingLogin} />);
}

