import React, { Component, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import './PasswordValidations.css';
import { format } from 'react-string-format';
import ErrorBoxCS from '../../UI/ErrorBox/ErrorBox';
import { GetConfiguration } from '../../Configuration/ConfigurationManager';
import Button from '../../UI/Buttons/Button';
import InputWrapper from '../../UI/Inputs/InputWrapper';
import { ErrorBox, ProcessErrors } from '../../Utilities/ErrorBox';
import { useAuth } from '../AuthenticationProvider'
import InputWrapperRefacture from '../../UI/Inputs/InputWrapperRefacture';
import setStateAsync from '../../Utilities/SetStateAsync';

export function Password(props) {
    const { t, i18n } = useTranslation();
    const [sent, setSent] = useState(false);
    const [errors, setErrors] = useState([]);
    const { environment } = useParams();
    const auth = useAuth();
    const [passwordRules, setPasswordRules] = useState(null);
    const [passwordCounts, setPasswordCounts] = useState({ length: 0, upper: 0, lower: 0, digit: 0, special: 0 });
    const [password, setUserPassword] = useState("");
    const [pswStatus, setPswStatus] = useState("initial");
    const [pswError, setPswError] = useState(false);
    const [focused, setFocused] = useState(false);
    const [buttonEvent, setButtonEvent] = useState(false);
    const [IsBlank, setIsBlank] = useState({password:false});
   



    //  const { token } = useParams();

    function getPasswordRules() {
      
        if (!passwordRules) {
            GetConfiguration("userconfiguration", environment).then((configuration) => {
                setPasswordRules(JSON.parse(configuration.passwordrules));
            });
         
        }
    }

    

    //password change handler

    const onPasswordChanged = (event) => {
       
        let psw=event.target.value;
        let updatedCounts = {};
        if(psw.length<1){setPswError(false)}
        updatedCounts.length = psw.length;
        updatedCounts.upper = (psw.match(/[A-Z]/g) || []).length;
        updatedCounts.lower = (psw.match(/[a-z]/g) || []).length;
        updatedCounts.digit = (psw.match(/[0-9]/g) || []).length;
        updatedCounts.special = (psw.match(/[^A-Za-z0-9]/g) || []).length;
        setPasswordCounts(updatedCounts);
        isPasswordValid(updatedCounts)
        props.setUserPassword(event.target.value)
        setUserPassword(event.target.value)
    }


    //for getting the UI of a password rule

    const getPswRuleUI = (ruleText, iconClass) => {
        return <div className={iconClass == "unchecked" ? "password-rule-fail " : iconClass == "checked" ? "password-rule-pass " : "password-rule"}>
            <span className={iconClass}></span>
            <span className="password-rule-text">{ruleText}</span></div>
    }

    // function returning the psw rules as per the validations

   

    const getPswRulesUI = () => {

        return <div className="password-rules-div" style={{ borderLeft: pswStatus == "invalid" && pswError ? "2px solid red" : pswStatus == "valid" ? "2px solid green" : "2px solid black" }}>
            <div className="password-rule-title">{t('register.passwordmusttext')}:</div>
            <div className="password-rules">
           
       
      
          {passwordRules?.minlength !== 0 ?
            getPswRuleUI(format(t('register.passwordruleslength'), passwordRules?.minlength),
                passwordCounts.length >= passwordRules?.minlength ? "checked" :
                    (passwordCounts.length < passwordRules?.minlength) && pswError ? "unchecked" : "bullet-circle"):""}
               
           {passwordRules?.upper !== 0 ?
            getPswRuleUI(format(t('register.passwordupper'), passwordRules?.upper),
                passwordCounts.upper >= passwordRules?.upper ? "checked" :
                    (passwordCounts.upper < passwordRules?.upper) && pswError ? "unchecked" : "bullet-circle"):""}
          
            {passwordRules?.digit !== 0 ?
              getPswRuleUI(format(t('register.passwordnumber'), passwordRules?.digit),
                passwordCounts.digit >= passwordRules?.digit ? "checked" :
                    (passwordCounts.digit < passwordRules?.digit) && pswError ? "unchecked" : "bullet-circle"):""}
            {passwordRules?.special !== 0 ?
            getPswRuleUI(format(t('register.passwordspecial'), passwordRules?.special),
                passwordCounts.special >= passwordRules?.special ? "checked" :
                    (passwordCounts.special < passwordRules?.special) && pswError ? "unchecked" : "bullet-circle"):""}
            {passwordRules?.lower !== 0 ?
            getPswRuleUI(format(t('register.passwordlower'), passwordRules?.lower),
                passwordCounts.lower >= passwordRules?.lower ? "checked" :
                    (passwordCounts.lower < passwordRules?.lower) && pswError ? "unchecked" : "bullet-circle"):""} 
                        
            </div>
        </div>
    }

    //checking the password validity

    const isPasswordValid = (pc) => {
    

        {
            if (((passwordRules?.minlength !==0 && pc.length >= passwordRules?.minlength) || passwordRules?.minlength === 0)
            && ((passwordRules?.upper !== 0 && pc.upper >= passwordRules?.upper) || passwordRules?.upper === 0  )
            && ((passwordRules?.lower !== 0 && pc.lower >= passwordRules?.lower) || passwordRules?.lower === 0 )
            && ((passwordRules?.digit !== 0 && pc.digit >= passwordRules?.digit) || passwordRules?.digit === 0)
            && ((passwordRules?.special !== 0 && pc.special >= passwordRules?.special) || passwordRules?.special === 0 )) {
               
                setPswStatus("valid")
                props.setPswValid("true");

            }
            else {
                setPswStatus("invalid")
                props.setPswValid("false");
            }
        }
    }


    //onFocus handler to handle the psw status message
    const handleOnFocus = () => {
         setStateAsync(()=>{
            setFocused(true);
        })
       
    }


    //onBlur handler checking the psw validity to handle the psw error message
    const handleOnBlur = (e) => {
       if(buttonEvent){
            document.getElementById("password").focus()
            setButtonEvent(false)
            e.preventDefault();
         }
       
        else{

            //as handleOnBlur does not work synchronously when it is with setState
            setStateAsync(()=>{
               setFocused(false)
                if(password.length<1) 
                { setPswError(false)
                props.setIsBlank(IsBlank=>({...IsBlank, newpassword:true, password:true}))
                setIsBlank(IsBlank=>({...IsBlank,password:true}))
               
                }
                else
                {props.setIsBlank(IsBlank=>({...IsBlank, newpassword:false, password:false}))
                 setIsBlank(IsBlank=>({...IsBlank,password:false}))}
                 setPswError((pswStatus==="invalid" && password.length>1) ? true : false)
            })
           
        }
    }

    //email validation
   
    const emailRegex= /^\w+([\.-/+?`$-_%&=~!'|#{*^}]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    
    //fetching password rules
    getPasswordRules();

    return (<>
    
      
            <InputWrapperRefacture label={props.label}
                id="password"
                value={password}
                inputSize="full-width"
                setButtonEvent={setButtonEvent}
                buttonEvent={buttonEvent}
                onBlur={(e) => { handleOnBlur(e) }}
                onFocus={(e) => { handleOnFocus(e) }}
                showBtnText={t('input.showpasswordlabel')}
                hideBtnText={t('input.hidepasswordlabel')}
                name="password"
                inputFieldType="password"
                invalidClass={pswError || IsBlank.password ||props.IsBlank.newpassword ||props.IsBlank.password ? "Invalid-input" : ""}
                onChange={onPasswordChanged}
                aria-invalid={pswError || IsBlank.password ||props.IsBlank.newpassword ||props.IsBlank.password }
                aria-describedby="password-errors"
                {...props} />
    
         <span role="alert" id="password-errors">
        {props.IsBlank.password ? <ErrorBoxCS>{t('register.blankpassword')}</ErrorBoxCS> : props.IsBlank.newpassword ? <ErrorBoxCS>{t('updatepassword.blanknewpassword')}</ErrorBoxCS>:""} 
        {pswError && <ErrorBoxCS> {t('register.passworderror')}</ErrorBoxCS>}  {/*to show on blur error message*/}
        {focused && getPswRulesUI()}  {/*to show on focus psw status*/}
      
        </span>

    </>)
}

