import React, { Component, Fragment, useEffect } from 'react';
import { Container } from 'reactstrap';
import AbacusFooter from './Layout/AbacusFooter/AbacusFooter';
import AbacusHeader from './Layout/AbacusHeader/AbacusHeader';
import { GenericErrorBox } from './UI/ErrorBox/GenericErrorBox';

const Layout = props => { 
    console.log(props);
    return (
          <div className="wrapper">
            <AbacusHeader brandHeader={props.brandingData?.headerData}/>
            <GenericErrorBox errors={props.auth.errors} setErrors={props.auth.setErrors} universal={true} />
            <div role="main" className="wrapper-content"> 
                    {props.children} 
             </div>
            <AbacusFooter brandFooter={props.brandingData?.footerData} />
      </div>
    );
  }

export default Layout;
