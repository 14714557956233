import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../i18n'
import { useParams, Link } from 'react-router-dom'
import { ErrorBox, ProcessErrors } from '../../Utilities/ErrorBox';
import { Loading } from '../../Utilities/Loading';
import { IsSafeUrl, RedirectWarning } from '../../Utilities/SafeUrl';
import { GenericErrorBox } from '../../UI/ErrorBox/GenericErrorBox';
import { APIPost } from '../../API/APIRequest';
import useCreateUrl from '../../../Hooks/useCreateUrl';

export function Auth0Callback(props) {
    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isNotTrusted, setIsNotTrusted] = useState(false);
 
    const { environment } = useParams();
    const params = (new URL(document.location)).searchParams;    
    const state = params.get("state");
    const code = params.get("code")
    var returnUrl = params.get("ReturnUrl");
    const createUrl = useCreateUrl();

    useEffect(() => {
        if (isLoading) {
            ProcessUniversalAuthentication();
            setIsLoading(false);
        }
    });

    const ProcessUniversalAuthentication = () => {       
        var data = {
            ReturnUrl: returnUrl,
            state: state,
            code: code
        };

        APIPost({
            "controller": "auth0",
            "action": "AuthorizeCallback",
            "environment": environment,
            data: data
        }).then((response) => {
            var result = response.data;
            if (result.success) {
                if (!returnUrl) {
                    returnUrl = window.location.origin;
                }

                var returnUri = new URL(returnUrl);
                returnUri.searchParams.append("ref", result.refToken);

                returnUrl = returnUri.href;

                IsSafeUrl(returnUrl, environment).then((result) => {
                    if (result) {
                        window.location.href = returnUrl;
                    }
                    else {
                        if (!isNotTrusted) {
                            setIsNotTrusted(true);
                        }
                    }  

                }).catch((error) => {
                    setErrors(ProcessErrors(error));
                });
            }
            else {
                setErrors(ProcessErrors(result.errors));
                window.location.href = createUrl("warning");
    
            }
        });
    };

    
    return (
        <div className="center-column">
            <Loading loading={isLoading} />
         
            <RedirectWarning isNotTrusted={isNotTrusted} returnUrl={returnUrl} />
        </div>
    );
}

