import React, { Component, useState } from 'react';
import { useParams, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import '../../i18n';
import { ErrorBox, ProcessErrors } from '../Utilities/ErrorBox';
import { APIGet } from '../API/APIRequest';
import { GenericErrorBox } from '../UI/ErrorBox/GenericErrorBox';
 
import usePageTitle from '../../Hooks/usePageTitle';

export function ClearCache(props) {

    usePageTitle(`Clear cache - ${props.brandingElement?.id}`);
    const { t, i18n } = useTranslation();   
    const [sent, setSent] = useState(false);
    const [errors, setErrors] = useState([]);
    const [cacheCleared, setCacheCleared] = useState(false);

    const { environment } = useParams();
    const clearCache = () => {
      
        APIGet({
            "controller": "cache",
            "action": "clearcache",
            "environment": environment
        }).then((response) => {
            setCacheCleared(true);
        }).catch((errors) => {
            setErrors(ProcessErrors(errors));
        });     
    }

    if (!cacheCleared && errors.length == 0) {
        clearCache();
    }

  

    return (
        <div className="center-column">
           <GenericErrorBox errors={errors} />
            {cacheCleared ? <>
                <p>Cache cleared</p>
            </>
                : <p></p>
        }
        </div> )
}

