import React, { useState, useEffect, useRef, Fragment } from 'react';
import './Transition.css';

const Transition = ({ timer, children, mountAnimation, containerClass }) => {

    const [mountState, setMountState] = useState("mount-start")

    useEffect(() => {
        setTimeout(() => {
            setMountState(mountAnimation)
        }, timer || 200)

    }, [])

    useEffect(() => {
        return () => {
            setMountState("unmount")
            //not working currently
        };
    }, []);

    console.log(mountState)

    return <div className={`${containerClass !== undefined ? containerClass :""} transition-onMount ${mountState && mountState}`}>
        {children}
    </div>
}

export default Transition;